


























































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import FormContainer from "@/components/FormContainer.vue";
import DatePicker from "@/components/DatePicker.vue";

@Component({
  components: {
    FormContainer,
    DatePicker
  }
})
export default class ActivityRecordsExtractions extends Vue {
  @Prop()
  public provider!: any;

  private projects: any = [];
  private users: any = [];
  private activities: any = [];
  private filters: any = [];

  
  @Watch("filters", { deep: true })
  onItemChange() {
    if (!this.filters) {
      this.initializeItem();
    }
  }

  async mounted() {
    const proj = (await this.$service.providers.extractionProjects.fetchItemsAsync()).items;
    this.projects = proj.map((i: any) => i.title);

    const allUsers = (await this.$service.providers.extractionUsers.fetchItemsAsync()).items;
    this.users = allUsers.map((i: any) => i.firstName + " " + i.lastName);
    
    const allActivities = (await this.$service.providers.activities.fetchItemsAsync()).items;
    this.activities = allActivities.map((i: any) => i.name);

    this.initializeItem();
  }

  getDinstinct(arr: any) {
    return Array.from(
      arr.filter(
        (value: any, index: any, self: any) => self.indexOf(value) === index
      )
    );
  }

  initializeItem() {
    this.filters = {
      projects: [],
      users: [],
      activities: [],
      dateOfSession: null,
      dateTo: null
    }
  }
}
