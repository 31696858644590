
























































































































































import ICrudClient from "@/lib/ICrudClient";
import DataProvider from "@/lib/DataProvider";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Location } from "vue-router";
import QTableEx from "./QTableEx.vue";
import ActivityRecordsExtractions from "@/components/extractionForms/ActivityRecordsExtractionsForm.vue";
import ProgrammesExtractionForm from "@/components/extractionForms/ProgrammesExtractionForm.vue";
import StrategicCommunicationsExtractionsForm from "@/components/extractionForms/StrategicCommunicationsExtractionsForm.vue";
import { saveAs } from "file-saver";

@Component({
  components: {
    QTableEx,
    ActivityRecordsExtractions,
    ProgrammesExtractionForm,
    StrategicCommunicationsExtractionsForm
  }
})
export default class ModelTable extends Vue {
  @Prop({ default: () => "multiple" })
  public selection!: string;

  @Prop()
  public viewPermission!: (() => boolean) | undefined;

  @Prop({ default: () => null })
  public addPermission!: (() => boolean) | undefined;

  @Prop({ default: () => null })
  public editPermission!: (() => boolean) | undefined;

  @Prop({ default: () => null })
  public deletePermission!: (() => boolean) | undefined;

  @Prop({ default: () => null })
  public columns!: any[];

  @Prop({ default: () => false })
  public allowExpand!: boolean;

  @Prop({ default: () => false })
  public extractions!: boolean;

  @Prop({ default: () => false })
  public hideTop!: boolean;

  @Prop({ default: () => false })
  public hideSearch!: boolean;

  @Prop({ default: () => "" })
  public title!: string;

  @Prop({ default: () => "Name" })
  public contextCellName!: string;

  @Prop()
  public disablePagination!: boolean;

  @Prop({
    default() {
      return (i: any) => {
        if (i) {
          return i[(this as any).contextCellName];
        }
      };
    }
  })
  public getName!: (item: any) => string;

  @Prop({ default: () => "" })
  public sortBy!: string;

  @Prop({ default: () => false })
  public descending!: boolean;
  @Prop()
  public addRoute!: (id: string) => Location;

  @Prop()
  public viewRoute!: (id: string) => Location;

  @Prop({ default: () => null })
  public provider!: DataProvider<any>;

  @Prop({ default: () => null })
  public crud!: ICrudClient<any>;

  @Prop({ default: () => [] })
  public selected!: any[];

  private internalSelected: any[] = [];
  @Watch("internalSelected")
  onSelectedChanged() {
    this.$emit("update:selected", this.internalSelected);
  }
  private deleting = false;
  private deleteDialog = false;
  private openDialog = false;
  public filter = "";

  public refresh() {
    (this.$refs.table as any).refresh();
  }
  @Watch("provider", { immediate: true })
  onProviderChanged() {
    if (this.provider) {
      this.internalSelected = [];
    }
  }

  private contextCellNameCalculated() {
    return "body-cell-" + this.contextCellName;
  }

  private async deleteItems() {
    this.deleteDialog = true;
  }

  private async deleteItemsInternal() {
    this.deleting = true;
    try {
      if (this.provider && this.crud) {
        const idsToDelete = this.internalSelected.map(i => i.id);
        await this.crud.deleteAsync(idsToDelete);
        this.internalSelected = [];
        (this.$refs.table as any).onProviderChanged();
      }
    } catch (e) {
      console.error(e);
      this.$q.notify({
        message:
          "This item could not be deleted because it is used by other items.",
        color: "red-4"
      });
    }
    this.deleteDialog = false;
    this.deleting = false;
  }

  private navigateToRoute(route: (id: string) => Location, id: any) {
    if (!route) {
      return;
    }
    const res = route(id);
    return res;
  }
}
