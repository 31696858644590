













































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import FormContainer from "@/components/FormContainer.vue";
import DatePicker from "@/components/DatePicker.vue";

@Component({
  components: {
    FormContainer,
    DatePicker,
  },
})
export default class ProgrammesExtractionsForm extends Vue {
  @Prop()
  public provider!: any;

  private types: any = [];
  private locations: any = [];
  private filters: any = [];

  @Watch("filters", { deep: true })
  onItemChange() {
    if (!this.filters) {
      this.initializeItem();
    }
  }

  async mounted() {
    const allLocations = (await this.$service.providers.locations.fetchItemsAsync()).items;
    this.locations = allLocations.map((i: any) => i.value);

    this.initializeItem();
  }

  getDinstinct(arr: any) {
    return Array.from(
      arr.filter(
        (value: any, index: any, self: any) => self.indexOf(value) === index
      )
    );
  }

  initializeItem() {
    this.filters = {
      types: [],
      locations: [],
      startDate: null,
      endDate: null
    }
  }
}
