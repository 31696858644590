




























import { Component, Vue, Prop } from "vue-property-decorator";
import ModelTable from "@/components/ModelTable.vue";
import { Location } from "vue-router";
import DataProvider from "@/lib/DataProvider";
import { HouseholdMember } from "@/data/models/HouseholdMember";
import { saveAs } from "file-saver";

@Component({
  components: {
    ModelTable
  }
})
export default class UploadedFilesTable extends Vue {
  @Prop()
  public provider!: DataProvider<HouseholdMember>;

  @Prop()
  public columns!: any[];

  @Prop()
  public viewRoute!: Location;

  @Prop()
  public addRoute!: Location;

  async download(name: string) {
    this.$forceUpdate();
    try {
      this.downloadBlob(await this.$service.downloadFile(name), name);
    } catch (e) {
      console.error(e);
    }
    this.$forceUpdate();
  }
  private downloadBlob(blob: Blob, name: string) {
    saveAs(
      blob,
      name
        .split("-")
        .slice(5)
        .join("-")
    );
  }
}
