























































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import FormContainer from "@/components/FormContainer.vue";
import DatePicker from "@/components/DatePicker.vue";

@Component({
  components: {
    FormContainer,
    DatePicker,
  },
})
export default class StrategicCommunicationsExtractionsForm extends Vue {
  @Prop()
  public provider!: any;

  private communicationTypes: any = [];
  private thematicCategories: any = [];
  private projects: any = [];
  private filters: any = [];

  @Watch("filters", { deep: true })
  onItemChange() {
    if (!this.filters) {
      this.initializeItem();
    }
  }

  async mounted() {
    const values = await this.provider.fetchItemsAsync();
    const items = values.items;

    this.communicationTypes = items.map((i: any) => i.communicationType).filter((x: any) => x);
    this.communicationTypes = this.getDinstinct(this.communicationTypes.map((i: any) => i.name));

    this.projects = items.flatMap((i: any) => i.projects).filter((x: any) => x);
    this.projects = this.getDinstinct(this.projects.map((i: any) => i.title))

    this.thematicCategories = items.flatMap((i: any) => i.thematicCategories).filter((x: any) => x);
    this.thematicCategories = this.getDinstinct(this.thematicCategories.map((i: any) => i.value))

    this.initializeItem();
  }

  getDinstinct(arr: any) {
    return Array.from(
      arr.filter(
        (value: any, index: any, self: any) => self.indexOf(value) === index
      )
    );
  }

  initializeItem() {
    this.filters = {
      communicationTypes: [],
      projects: [],
      thematicCategories: [],
      startDate: null,
      endDate: null
    }
  }
}
