var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.viewPermission !== undefined && _vm.viewPermission())?_c('div',[(!_vm.hideTop)?_c('q-toolbar',{staticClass:"bg-secondary"},[_c('div',{staticClass:"q-gutter-sm"},[(_vm.addRoute && _vm.addPermission && _vm.addPermission())?_c('q-btn',{staticClass:"bg-green-5 text-white",attrs:{"icon":"add","label":"Add new"}},[_c('router-link',{staticClass:"absolute full-width full-height",attrs:{"exact":"","to":_vm.navigateToRoute(_vm.addRoute)}})],1):_vm._e(),_vm._t("top-actions")],2)]):_vm._e(),_c('q-table-ex',_vm._b({ref:"table",staticClass:"table-ex sticky-column-table",attrs:{"provider":_vm.provider,"bordered":false,"selection":_vm.selection,"selected":_vm.internalSelected,"columns":_vm.columns,"row-key":"id","filter":_vm.filter,"allowExpand":_vm.allowExpand,"sortBy":_vm.sortBy,"descending":_vm.descending,"flat":""},on:{"update:selected":function($event){_vm.internalSelected=$event},"update:filter":function($event){_vm.filter=$event}},scopedSlots:_vm._u([(_vm.viewRoute)?{key:"row-actions",fn:function(ref){
var scope = ref.scope;
return [(_vm.editPermission && _vm.editPermission())?_c('q-btn',{attrs:{"icon":"visibility","flat":"","no-caps":""}},[_c('router-link',{staticClass:"absolute full-width full-height",attrs:{"exact":"","to":_vm.navigateToRoute(_vm.viewRoute, scope.row)}}),_c('q-tooltip',[_vm._v("Open")])],1):_vm._e(),_vm._t("row-buttons",null,null,{ scope: scope })]}}:null,{key:"top",fn:function(){return [_c('div',{staticClass:"col-md-6 row"},[_vm._t("title",[_c('div',{staticClass:"text-h5 q-pr-sm"},[_vm._v(_vm._s(_vm.title))])]),(_vm.extractions)?_c('q-btn',{attrs:{"icon":"download","label":"Extract","flat":"","no-caps":""},on:{"click":function($event){_vm.openDialog = true}}}):_vm._e(),(_vm.extractions)?_c('q-dialog',{attrs:{"persistent":"","scrollable":""},model:{value:(_vm.openDialog),callback:function ($$v) {_vm.openDialog=$$v},expression:"openDialog"}},[(_vm.provider.builder.entity === 'ActivityRecords')?_c('activity-records-extractions',{attrs:{"provider":_vm.provider}}):_vm._e(),(_vm.provider.builder.entity === 'Programmes')?_c('programmes-extraction-form',{attrs:{"provider":_vm.provider}}):_vm._e(),(_vm.provider.builder.entity === 'StrategicCommunications')?_c('strategic-communications-extractions-form',{attrs:{"provider":_vm.provider}}):_vm._e()],1):_vm._e()],2),_c('div',{staticClass:"col-md-6 col-12"},[_c('div',{staticClass:"row q-col-gutter-sm items-center"},[_c('div',{staticClass:"col-md-auto col-sm-12 col-xs-12 q-gutter-sm"},[(
                _vm.deletePermission &&
                  _vm.deletePermission() &&
                  _vm.internalSelected.length > 0
              )?_c('q-btn',{staticClass:"bg-red-4 text-white",attrs:{"icon":"delete","label":"Delete Selected","loading":_vm.deleting},on:{"click":function($event){return _vm.deleteItems()}}}):_vm._e(),_vm._t("selected-actions")],2),_c('div',{staticClass:"col-md col-sm-12 col-xs-12"},[(!_vm.hideSearch)?_c('q-input',{attrs:{"dense":"","outlined":"","bg-color":"white","debounce":"300","placeholder":"Search"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('q-icon',{attrs:{"name":"search"}})]},proxy:true}],null,false,4009527860),model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}}):_vm._e()],1)])])]},proxy:true},_vm._l((_vm.$scopedSlots),function(_,name){return {key:name,fn:function(slotData){return [_vm._t(name,null,null,slotData)]}}})],null,true)},'q-table-ex',Object.assign({}, _vm.$props, _vm.$attrs),false)),_c('q-dialog',{model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_vm._t("deletePopup",[_c('q-card',[_c('q-card-section',{attrs:{"title":""}},[_c('div',{staticClass:"text-h6"},[_vm._v("Delete")])]),_c('q-separator'),_c('q-card-section',[_vm._v(" Are you sure that you want to delete "),_c('strong',[_vm._v(_vm._s(_vm.internalSelected.map(function (i) { return this$1.getName(i); }).join(", ")))]),_vm._v(" ? ")]),_c('q-separator'),_c('q-card-actions',{attrs:{"align":"right"}},[_c('q-btn',{directives:[{name:"close-popup",rawName:"v-close-popup"}],attrs:{"flat":"","label":"Cancel"}}),_c('q-btn',{attrs:{"label":"Confirm","color":"red-4","loading":_vm.deleting},on:{"click":_vm.deleteItemsInternal}})],1)],1)],null,{ internalSelected: _vm.internalSelected })],2)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }